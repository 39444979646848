.speech-bubble {
    position: relative;
    background: #e6e6e6;
    border-radius: 0.4em;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 27px solid transparent;
    border-right-color: #e6e6e6;
    border-left: 0;
    margin-top: -27px;
    margin-left: -27px;
}

.speech-bubble-right {
    position: relative;
    background: #b9bdff;
    border-radius: 0.4em;
}

.speech-bubble-right:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 27px solid transparent;
    border-left-color: #e6e6e6;
    border-right: 0;
    margin-top: -27px;
    margin-right: -27px;
}

.bubble-content {
    padding: 1em;
}
